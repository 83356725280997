






































import ContentThin from '@/components/elements/ContentThin.vue';
import NavRouterLinks from '@/components/elements/NavRouterLinks.vue';
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
             components: {ContentThin, NavRouterLinks}
           })
export default class UndersideHeader extends Vue {
  @Prop({default: {menuActive: false}}) protected pageData!: object;
  @Prop({default: ''}) protected pageHeaderTitle!: string;
  @Prop({default: ''}) protected pageHeaderTags!: string;
  @Prop({default: ''}) protected pageHeaderSubLine!: string;
}
