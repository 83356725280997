import { render, staticRenderFns } from "./UndersideHeader.vue?vue&type=template&id=13cf0b53&"
import script from "./UndersideHeader.vue?vue&type=script&lang=ts&"
export * from "./UndersideHeader.vue?vue&type=script&lang=ts&"
import style0 from "./UndersideHeader.vue?vue&type=style&index=0&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/home/florian/Projects/get-a-shop-website/vuetify_getashop_promote/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VImg } from 'vuetify/lib'
import { VToolbar } from 'vuetify/lib'
installComponents(component, {VBtn,VContainer,VImg,VToolbar})
